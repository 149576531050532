export class SessionUser {
    detailId: number = -1;
    meetingName: string = '';
    sessionId: number = -1;
    sessionDate: string = '';
    userKey: string = '';
    isModeratorRecord: boolean = false;
    actingAsModerator: boolean = false;
    handlingValue: string = '';
    handlingX: number = 0;
    handlingY: number = 0;
    understandableValue: string = '';
    understandableX: number = 0;
    understandableY: number = 0;
    meaningfulnessValue: string = '';
    meaningfulnessX: number = 0;
    meaningfulnessY: number = 0;
    recoverValue: string = '';
    recoverX: number = 0;
    recoverY: number = 0;

    constructor(user: SessionUser) {
        if (user) {
            this.detailId = user.detailId;
            this.meetingName = user.meetingName;
            this.sessionId = user.sessionId;
            this.sessionDate = user.sessionDate;
            this.userKey = user.userKey;
            this.handlingValue = user.handlingValue;
            this.understandableValue = user.understandableValue;
            this.meaningfulnessValue = user.meaningfulnessValue;
            this.recoverValue = user.recoverValue;
            this.isModeratorRecord = user.isModeratorRecord;
            this.actingAsModerator = user.actingAsModerator;
        }
    }

    public getSectionValue(section: string): string {
        if (section == "manageability")
            return this.handlingValue;
        else if (section == "comprehensibility")
            return this.understandableValue;
        else if (section == "meaningfulness")
            return this.meaningfulnessValue;
        else if (section == "recovery")
            return this.recoverValue;
        else
            return '';
    }

    public setSectionValue(section: string, value: string) {
        if (section == "manageability")
            this.handlingValue = value;
        else if (section == "comprehensibility")
            this.understandableValue = value;
        else if (section == "meaningfulness")
            this.meaningfulnessValue = value;
        else if (section == "recovery")
            this.recoverValue = value;
    }
}