<div class="wrapper">
    <div class="overlay" *ngIf="getIsLoading()">
        <div class="spinner-wrapper">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div class="loaded-content" [class.blurred]="getIsLoading()">
        <ng-content></ng-content>
    </div>
</div>

<div id="content-mobile" style="margin-top: 25px;">
    <div *ngFor="let header of getHeaderItems()">
        <div class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
            <h3 style="text-align: left;">{{header.textString}}</h3>
        </div>
        <div class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12"
            style="text-align: left; padding: 20px; font-size: 17px; white-space: pre-line">
            {{getBodyItems(header.key)}}
        </div>
    </div>
</div>

<div id="content-desktop" style="margin-top: 40px;">
    <div class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12"
        style="text-align: center;">
        <h4>
            {{getLanguageItem( 'reflexlinktext' )}}: <a href="https://00019.reflex.scania.com/00019/189.html?"
                target="_blank">Reflex</a>
        </h4>
    </div>

    <div style="display: flex; justify-content: center; margin-top:20px">
        <div class="tds-col-xxlg-6 tds-col-xlg-6 tds-col-lg-6 tds-col-md-6 tds-col-sm-6 tds-col-xs-6">
            <tds-accordion>
                <tds-accordion-item *ngFor="let header of getHeaderItems()">
                    <div slot="header">
                        <h4>{{header.textString}}</h4>
                    </div>
                    <p style="text-align: left;padding: 20px;font-size: 17px;white-space: pre-line">
                        {{getBodyItems(header.key)}} </p>
                </tds-accordion-item>
            </tds-accordion>
        </div>
    </div>
</div>