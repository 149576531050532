import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ReturnObject } from '../model/returnobject';
import { Language } from '../model/language';
import { LanguageItem } from '../model/languageitem';
import { AdminService } from '../service/admin.service';
import { InternalMsgService } from '../service/internal.msg.service';
import { LanguageService } from '../service/language.service';
import { LanguageListService } from '../service/languagelist.service';
import { SettingsService } from '../service/settings.service';
import { LanguageAdmin } from '../model/languageadmin';
import { errorCopy } from '../common/errorCopy';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
    static snackBarErrMsg: string = '';
    languageSubscr: Subscription;
    languageItemList: LanguageItem[] = [];
    languageList: Language[] = [];
    selected: string = "";
    addTranslation: boolean = false;
    modifyLanguage: boolean = false;
    loading: boolean = false;
    newLanguage: Language;
    newLanguageItemList: LanguageItem[] = [];
    englishLanguageItemList: LanguageItem[] = [];
    translationLanguageItemList: LanguageItem[] = [];
    fullLanguageName: string = "";
    shortLanguageName: string = "";
    updateFullLanguageName: string = "";
    updateShortLanguageName: string = "";
    languagePublished: boolean = true;
    newHeader: string = "";
    newBody: string = "";
    panelOpenInfoTexts = false;
    panelOpenAppTexts = false;
    panelSessionQuestionsTexts = false;
    englishLanguage: string = "2";

    constructor(private languageListService: LanguageListService,
        private msgService: InternalMsgService,
        private languageService: LanguageService,
        private adminService: AdminService,
        public dialog: MatDialog,
        private settingsService: SettingsService,
        private snackBar: MatSnackBar,
        private errCopy: errorCopy) { }

    ngOnInit(): void {
        this.msgService.onSelectedMenuSet(3);
        this.initServices();
        this.selected = this.settingsService.getSelectedAdminLanguage().toString();

        this.panelOpenInfoTexts = this.settingsService.getOpenedLanugagePanel() == "infotexts";
        this.panelOpenAppTexts = this.settingsService.getOpenedLanugagePanel() == "apptexts";
    }

    initServices() {
        this.languageSubscr = this.languageListService.languageitemlist$.subscribe(
            words => {
                this.languageItemList = words;
            });
        this.languageListService.fetchData();
        this.fetchLanguages();

        this.englishLanguageItemList = this.settingsService.getAdminLangEnglish();
        if (this.englishLanguageItemList == null) {
            this.getLanguageList(this.englishLanguage, false);
        }

        this.languageSubscr = this.languageListService.languagelist$.subscribe(
            words => {
                this.languageList = words;
                this.getSelection();
            });
    }

    openDialog(event: { currentTarget: { id: any; name: any; }; }) {
        var eventId = event.currentTarget.id;
        var eventName = event.currentTarget.name;
        const dialogRef = this.dialog.open(AdminDialog, {
            width: '250px',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == "Yes") {
                if (eventId == "deleteLanguage") {
                    if (this.settingsService.getSelectedAdminLanguage() == "2")
                        this.openSnackBarInfo("Cannot delete the English language")
                    else if (this.languageList.find(x => x.id.toString() == this.settingsService.getSelectedAdminLanguage()).published)
                        this.openSnackBarInfo("Cannot delete a published language")
                    else
                        this.deleteLanguageFromDb();
                }
                else if (eventId == "deleteLanguageItem") {
                    this.getHeaderAndBodyToDelete(eventName);
                }
            }
        });
    }

    getLanguageList(id: string, translation: boolean): void {
        this.loading = true;

        this.languageService.getLanguageItemList(id)
            .subscribe({
                next: (result: ReturnObject<LanguageItem[]>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        this.loading = false;
                        return;
                    }
                    if (!translation) {
                        this.englishLanguageItemList = result.value;
                        this.settingsService.setAdminLangEnglish(this.englishLanguageItemList);
                    }
                    else if (translation) {
                        this.translationLanguageItemList = result.value;
                        this.settingsService.setAdminLangTranslate(this.translationLanguageItemList);
                        this.modifyLanguage = true;
                    }

                    this.loading = false;
                },
                error: (err) => {
                    this.loading = false;
                    this.openSnackBarErr(err.message);
                }
            });
    }
    
        // this.languageService.getLanguageItemList(id)
        //     .subscribe((data: ReturnObject<LanguageItem[]>) => {
        //         if (data.isFailure) {
        //             this.openSnackBarErr(data.failureMessage);
        //             this.loading = false;
        //             return;
        //         }
        //         if (!translation) {
        //             this.englishLanguageItemList = data.value;
        //             this.settingsService.setAdminLangEnglish(this.englishLanguageItemList);
        //         }
        //         else if (translation) {
        //             this.translationLanguageItemList = data.value;
        //             this.settingsService.setAdminLangTranslate(this.translationLanguageItemList);
        //             this.modifyLanguage = true;
        //         }

        //         this.loading = false;
        //     },
        //         err => {
        //             this.loading = false;
        //             this.openSnackBarErr(err.message);
        //         });

    postItemToDb(item: LanguageItem): void {
        this.loading = true;
        this.adminService.postItemToDb(item)
            .subscribe({
                next: (result: ReturnObject<LanguageItem>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        this.loading = false;
                        return;
                    }
                    this.loading = false;

                    if (item.id != -2) {
                        this.openSnackBarInfo("Text updated");
                    }
                    else {
                        this.openSnackBarInfo("Text created");
                    }

                    this.getLanguageList(this.settingsService.getSelectedAdminLanguage(), true);
                    this.languageListService.invalidateLanguage();
                    this.newHeader = "";
                    this.newBody = "";
                },
                error: (err) => {
                    this.loading = false;
                    this.openSnackBarErr(err.message);
                }
            });
    }

    postLanguageToDb(language: Language): void {
        this.loading = true;
        this.adminService.postLanguageToDb(language)
            .subscribe({
                next: (result: ReturnObject<Language>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        this.loading = false;
                        return;
                    }
                    this.fetchLanguages();
                    if (language.id == -1) {
                        this.openSnackBarInfo("Language added to Database");
                        this.setAdminPrivileges(result.value);
                    }
                    else
                        this.openSnackBarInfo("Language updated");
                    this.loading = false;
                },
                error: (err) => {
                    this.loading = false;
                    this.openSnackBarErr(err.message);
                }
            });
    }

    deleteLanguageFromDb(): void {
        this.loading = true;
        this.adminService.deleteLanguageFromDb(this.settingsService.getSelectedAdminLanguage())
            .subscribe({
                next: (result: ReturnObject<Language>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        this.loading = false;
                        return;
                    }
                    this.fetchLanguages();
                    this.openSnackBarInfo("Language successfully deleted from DB");
                    this.settingsService.setSelectedAdminLanguage("0");
                    this.modifyLanguage = false;
                    this.loading = false;
                },
                error: (err) => {
                    this.loading = false;
                    this.openSnackBarErr(err.message);
                }
            });
    }

    deleteLanguageItemFromDb(languageItemId: number): void {
        this.loading = true;
        this.adminService.deleteLanguageItemFromDb(languageItemId.toString())
            .subscribe({
                next: (result: ReturnObject<LanguageItem>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        this.loading = false;
                        return;
                    }
                    this.openSnackBarInfo("Text successfully deleted from DB");
                    this.getLanguageList(this.settingsService.getSelectedAdminLanguage(), true);
                    this.languageListService.invalidateLanguage();
                    this.loading = false;
                },
                error: (err) => {
                    this.loading = false;
                    this.openSnackBarErr(err.message);
                }
            });
    }

    getHeaderAndBodyToDelete(id: number): void {
        this.deleteLanguageItemFromDb(this.translationLanguageItemList.find(x => x.id == id).id);
        this.deleteLanguageItemFromDb(this.translationLanguageItemList.find(x => x.id == this.getInfopageItemBodyId(id)).id);
    }

    updateInfopageItem(event: { currentTarget: { id: number; }; }): void {
        this.postItemToDb(this.translationLanguageItemList.find(x => x.id == event.currentTarget.id));
        this.postItemToDb(this.translationLanguageItemList.find(x => x.id == this.getInfopageItemBodyId(event.currentTarget.id)));
    }

    updateSessionpageQuestionsItem(event: { currentTarget: { id: number; }; }): void {
        this.postItemToDb(this.translationLanguageItemList.find(x => x.id == event.currentTarget.id));
        this.postItemToDb(this.translationLanguageItemList.find(x => x.id == this.getSessionpageQuestionsItemBodyId(event.currentTarget.id)));
    }

    setAdminPrivileges(language: Language): void {
        var adminList = this.settingsService.getLanguagePrivileges();
        var adminUser = new LanguageAdmin();
        adminUser.globalId = adminList[0].globalId;
        adminUser.languageId = language.id;
        adminList.push(adminUser);
    }

    getSelectionOnChange(selection: MatSelectChange): void {
        this.settingsService.setSelectedAdminLanguage(selection.value);
        this.modifyLanguage = false;
        this.getLanguageList(this.settingsService.getSelectedAdminLanguage(), true);
        this.languagePublished = this.languageList.find(x => x.id.toString() == this.settingsService.getSelectedAdminLanguage()).published;
    }

    getSelection(): void {
        this.settingsService.setSelectedAdminLanguage(this.selected);
        this.modifyLanguage = false;

        this.translationLanguageItemList = this.settingsService.getAdminLangTranslate();
        if (this.translationLanguageItemList == null) {
            this.getLanguageList(this.settingsService.getSelectedAdminLanguage(), true);
        } else {
            this.modifyLanguage = true;
        }

        this.languagePublished = this.languageList.find(x => x.id.toString() == this.settingsService.getSelectedAdminLanguage()).published;
    }

    getSelLanguagName(): string {
        if (this.languageList && this.languageList.length > 0 && this.settingsService.getSelectedAdminLanguage())
            return this.languageList.find(x => x.id.toString() == this.settingsService.getSelectedAdminLanguage()).fullName
        else
            return '';
    }
    setLanguageItem(event: { target: { id: string; value: string; }; }): void {
        if (this.translationLanguageItemList.find(x => x.key == event.target.id)) {
            this.translationLanguageItemList.find(x => x.key == event.target.id).textString = event.target.value;
            this.postItemToDb(this.translationLanguageItemList.find(x => x.textString == event.target.value));
        }
        else {
            var newItem = new LanguageItem();
            newItem.languageId = Number.parseInt(this.settingsService.getSelectedAdminLanguage());
            newItem.textString = event.target.value;
            newItem.key = event.target.id;
            newItem.group = this.languageItemList.find(x => x.key == event.target.id).group;
            this.postItemToDb(newItem);
        }
    }

    createLanguage(): void {
        if (this.languageList.find(x => x.fullName == this.fullLanguageName.toLowerCase()) || this.languageList.find(x => x.shortName == this.shortLanguageName.toLowerCase()))
            this.openSnackBarInfo("Language already in Database");
        else {
            var newLanguage = new Language();
            newLanguage.fullName = this.fullLanguageName.toLowerCase();
            newLanguage.shortName = this.shortLanguageName.toLowerCase();
            this.postLanguageToDb(newLanguage);
        }
    }

    updateLanguage(): void {
        var updatedLanguage = new Language();
        updatedLanguage.id = Number.parseInt(this.settingsService.getSelectedAdminLanguage());
        if (this.updateFullLanguageName != "") {
            updatedLanguage.fullName = this.updateFullLanguageName.toLowerCase();
            this.updateFullLanguageName = "";
        }
        else {
            updatedLanguage.fullName = this.languageList.find(x => x.id.toString() == this.settingsService.getSelectedAdminLanguage()).fullName;
        }
        if (this.updateShortLanguageName != "") {
            updatedLanguage.shortName = this.updateShortLanguageName.toLowerCase();
            this.updateShortLanguageName = "";
        }
        else {
            updatedLanguage.shortName = this.languageList.find(x => x.id.toString() == this.settingsService.getSelectedAdminLanguage()).shortName;
        }
        if (this.languagePublished && this.englishLanguageItemList.filter(x => x.group != "home").length != this.translationLanguageItemList.filter(x => x.group != "home").length) {
            this.openSnackBarInfo("Not all translations are completed");
        }
        else {
            updatedLanguage.published = this.languagePublished;
            this.postLanguageToDb(updatedLanguage);
        }
    }

    createInfopageItem(): void {
        var newHeader = new LanguageItem();
        newHeader.languageId = Number.parseInt(this.settingsService.getSelectedAdminLanguage());
        newHeader.textString = this.newHeader;
        newHeader.key = "header" + (this.translationLanguageItemList.filter(x => x.group == "home" && x.key.startsWith("header")).length + 1);
        newHeader.group = "home";
        this.postItemToDb(newHeader);

        var newBody = new LanguageItem();
        newBody.languageId = Number.parseInt(this.settingsService.getSelectedAdminLanguage());
        newBody.textString = this.newBody;
        newBody.key = "body" + (this.translationLanguageItemList.filter(x => x.group == "home" && x.key.startsWith("body")).length + 1);
        newBody.group = "home";
        this.postItemToDb(newBody);
    }


    // -----------------------------------------------------------------------------------
    // Misc
    // -----------------------------------------------------------------------------------

    openSnackBarInfo(message: string): void {
        this.snackBar.open(message, 'close', {
            duration: 7000,
            panelClass: 'snackbar_info'
        });
    }

    openSnackBarErr(message: string): void {
        AdminComponent.snackBarErrMsg = 'An unexpected error occurred. The error was: ' + message;
        let snRef = this.snackBar.open(message, 'Copy and close', {
            duration: 20000,
            panelClass: 'snackbar_error'
        });
        this.errCopy.snackBarCopy(snRef, document, AdminComponent.snackBarErrMsg);
    }

    setOpenedLanugagePanel(panelName: string, action: string): void {
        if (panelName == 'apptexts' && action == 'open') {
            this.settingsService.setOpenedLanugagePanel(panelName)
            this.panelOpenAppTexts = true;
            this.panelOpenInfoTexts = false;
            this.panelSessionQuestionsTexts = false;
            return;
        }
        if (panelName == 'infotexts' && action == 'open') {
            this.settingsService.setOpenedLanugagePanel(panelName)
            this.panelOpenAppTexts = false;
            this.panelOpenInfoTexts = true;
            this.panelSessionQuestionsTexts = false;
            return;
        }
        if (panelName == 'sessionquestionstexts' && action == 'open') {
            this.settingsService.setOpenedLanugagePanel(panelName)
            this.panelOpenAppTexts = false;
            this.panelOpenInfoTexts = false;
            this.panelSessionQuestionsTexts = true;
            return;
        }

        this.settingsService.setOpenedLanugagePanel('');
        this.panelOpenAppTexts = false;
        this.panelOpenInfoTexts = false;
        this.panelSessionQuestionsTexts = false;
    }

    closeAppTextPanel(): void {
        if (this.panelOpenAppTexts) this.settingsService.setOpenedLanugagePanel('');
        this.panelOpenAppTexts = false;
    }

    closeInfoTextPanel(): void {
        if (this.panelOpenInfoTexts) this.settingsService.setOpenedLanugagePanel('');
        this.panelOpenInfoTexts = false;
    }

    closeSessionQuestionsTextPanel(): void {
        if (this.panelSessionQuestionsTexts) this.settingsService.setOpenedLanugagePanel('');
        this.panelSessionQuestionsTexts = false;
    }

    getAdminLanguages(): Language[] {
        return this.languageList.filter(x => this.settingsService.getLanguagePrivileges().map(y => y.languageId).includes(x.id));
    }

    getHeader(id: number): string {
        return this.translationLanguageItemList.find(x => x.id == id).textString;
    }

    setHeader(event: { target: { id: number; value: string; }; }): void {
        this.translationLanguageItemList.find(x => x.id == event.target.id).textString = event.target.value;
    }

    setBody(event: { target: { id: number; value: string; }; }): void {
        this.translationLanguageItemList.find(x => x.id == event.target.id).textString = event.target.value;
    }

    isCreateDisabled(): boolean {
        return this.fullLanguageName.length == 0 || this.shortLanguageName.length != 2;
    }

    getEnglishItemList(): LanguageItem[] {
        return this.englishLanguageItemList.filter(x => x.group != "home");
    }

    getInfopageItemHeaderList(): LanguageItem[] {
        var temp = this.translationLanguageItemList.filter(x => x.group == "home" && x.key.startsWith("header"));
        return temp;
    }

    getSessionpageQuestionsHeaderList(): LanguageItem[] {
        var temp = this.translationLanguageItemList.filter(x => x.group == "session" && x.key.startsWith("questionsmenu"));
        return temp;
    }

    getInfopageItemBody(key: string): string {
        var body = "body" + key.slice(6);
        if (this.translationLanguageItemList.find(x => x.group == "home" && x.key == body)) {
            return this.translationLanguageItemList.find(x => x.group == "home" && x.key == body).textString;
        }
        return '...';
    }

    getSessionpageQuestionsItemBody(key: string): string {
        var body = "bodyfor" + key;
        if (this.translationLanguageItemList.find(x => x.group == "session" && x.key == body)) {
            return this.translationLanguageItemList.find(x => x.group == "session" && x.key == body).textString;
        }
        return '...';
    }

    getInfopageItemBodyId(id: number): number {
        var key = this.translationLanguageItemList.find(x => x.id == id).key;
        var body = "body" + key.slice(6);
        if (this.translationLanguageItemList.find(x => x.group == "home" && x.key == body)) {
            return this.translationLanguageItemList.find(x => x.group == "home" && x.key == body).id;
        }
        return -1;
    }

    getSessionpageQuestionsItemBodyId(id: number): number {
        var key = this.translationLanguageItemList.find(x => x.id == id).key;
        var body = "bodyfor" + key;
        if (this.translationLanguageItemList.find(x => x.group == "session" && x.key == body)) {
            return this.translationLanguageItemList.find(x => x.group == "session" && x.key == body).id;
        }
        return -1;
    }

    getLanguageFullName(): string {
        return this.languageList.find(x => x.id.toString() == this.settingsService.getSelectedAdminLanguage()).fullName;
    }

    getLanguageShortName(): string {
        return this.languageList.find(x => x.id.toString() == this.settingsService.getSelectedAdminLanguage()).shortName;
    }

    getLanguageItem(item: string): string {
        if (this.languageItemList.find(x => x.key == item)) {
            return this.languageItemList.find(x => x.key == item).textString;
        }
        return '...';
    }

    getTranslationItem(key: string): string {
        if (this.translationLanguageItemList.find(x => x.key == key)) {
            return this.translationLanguageItemList.find(x => x.key == key).textString;
        }
        return '...';
    }

    fetchLanguages(): void {
        this.languageListService.getLanguageList();
    }
}

@Component({
    selector: 'admin.dialog',
    templateUrl: 'admin.dialog.html',
})
export class AdminDialog {

    constructor(public dialogRef: MatDialogRef<AdminDialog>) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onYesClick(): void {
        this.dialogRef.close("Yes");
    }

}
