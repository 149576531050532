export class User {
  name: string = '';
  role: string = '';
  department: string = '';
  id: string = '';
  azureUserName = '';
  accessFeatures: string[] = [];
  userKey: string = '';

  static getEmpty(): User {
    return new User();
  }
}