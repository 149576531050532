import { Directive, ElementRef, HostListener } from '@angular/core';
import { SessionComponent } from '../session/session.component';

@Directive({
  selector: '[appDraggable]'
})
export class DraggableDirective {
  constructor(private el: ElementRef,
    private sessionComponent: SessionComponent) {
    this.el.nativeElement.setAttribute('draggable', true);
  }

  @HostListener('dragstart', ['$event'])
  onDragStart(event) {
    const elementToBeDragged = event.target.getElementsByTagName('circle')[0];
    this.sessionComponent.droppedFace = elementToBeDragged.id;
  }

  @HostListener('document:dragover', ['$event'])
  onDragOver(event) {
      event.preventDefault();
  }
}
