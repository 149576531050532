import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { ReturnObject } from '../model/returnobject';
import { Observable } from 'rxjs';
import { Language } from '../model/language';
import { LanguageItem } from '../model/languageitem';

@Injectable( {
    providedIn: 'root'
} )
export class LanguageService {

    constructor( private api: APIService ) {
    }

    getLanguageItemList(id: string): Observable<ReturnObject<LanguageItem[]>> {
        return this.api.getAPI( `language/${id}` );
    }

    getLanguageList(): Observable<ReturnObject<Language[]>> {
        return this.api.getAPI( `language/languagelist` );
    }
}