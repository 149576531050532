import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { CompareComponent } from './compare/compare.component';
import { HomeComponent } from './home/home.component';
import { SessionComponent } from './session/session.component';
import { MsalGuard } from '@azure/msal-angular';
import { GroupGuardService } from './service/group-guard.service';
import { groups } from './auth-config';
import { MeetingComponent } from './meeting/meeting.component';

const routes: Routes = [
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [
            MsalGuard,
            GroupGuardService
        ],
        data: {
            expectedDeveloperGroup: groups.groupDevelopers,
            expectedTesterGroup: groups.groupTester,
            expectedRole: ''
        }
    },
    {
        path: 'session',
        component: SessionComponent,
        canActivate: [
            MsalGuard,
            GroupGuardService
        ],
        data: {
            expectedDeveloperGroup: groups.groupDevelopers,
            expectedTesterGroup: groups.groupTester,
            expectedRole: ''
        }
    },
    {
        path: 'session/:meetingkey',
        component: SessionComponent,
        canActivate: [
            MsalGuard,
            GroupGuardService
        ],
        data: {
            expectedDeveloperGroup: groups.groupDevelopers,
            expectedTesterGroup: groups.groupTester,
            expectedRole: ''
        }
    },
    {
        path: 'session/:meetingkey/:moderatorkey',
        component: SessionComponent,
        canActivate: [
            MsalGuard,
            GroupGuardService
        ],
        data: {
            expectedDeveloperGroup: groups.groupDevelopers,
            expectedTesterGroup: groups.groupTester,
            expectedRole: ''
        }
    },
    {
        path: 'meeting',
        component: MeetingComponent,
        canActivate: [
            MsalGuard,
            GroupGuardService
        ],
        data: {
            expectedDeveloperGroup: groups.groupDevelopers,
            expectedTesterGroup: groups.groupTester,
            expectedRole: ''
        }
    },
    {
        path: 'compare',
        component: CompareComponent,
        canActivate: [
            MsalGuard,
            GroupGuardService
        ],
        data: {
            expectedDeveloperGroup: groups.groupDevelopers,
            expectedTesterGroup: groups.groupTester,
            expectedRole: ''
        }
    },
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [
            MsalGuard,
            GroupGuardService
        ],
        data: {
            expectedDeveloperGroup: groups.groupDevelopers,
            expectedTesterGroup: groups.groupTester,
            expectedAdminGroup: groups.groupAdmin,
            expectedRole: 'admin'
        }
    },
    {
        // Needed for hash routing
        path: 'error',
        component: MeetingComponent
    },
    {
        // Needed for hash routing
        path: 'state',
        component: MeetingComponent
    },
    {
        // Needed for hash routing
        path: 'code',
        component: MeetingComponent
    },
    {
        path: '',
        redirectTo: '/meeting',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/meeting'
    }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            // Don't perform initial navigation in iframes
            initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled'
        })],
    exports: [RouterModule]
})
export class AppRoutingModule { }