import { Directive, HostListener } from '@angular/core';
import { SessionComponent } from '../session/session.component';

@Directive({
  selector: '[appDroppable]'
})
export class DroppableDirective {

  constructor(private sessionComponent: SessionComponent) {
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    this.sessionComponent.setDroppedFace(event.offsetX, event.offsetY);
  }
}
