<div style="display: flex; flex-direction: column; overflow-y: hidden;">
    <div style="min-height: 100%; display: flex; flex-direction: column; overflow-y: hidden;">
        <tds-header>
            <tds-header-hamburger
                id="demo-hamburger"
                aria-label="Open application drawer"
                aria-haspopup="true"
                aria-expanded="false"
                (click)="toggleMobileSideMenu()">
            </tds-header-hamburger>

            <tds-header-title style="cursor: pointer;" (click)="goToPage(1, 'meeting')">
                Work Balance {{getLanguageItem( 'dialoguetool' )}}
            </tds-header-title>

            <tds-header-item [selected]="getSelected(1) ? 'true' : null" *ngIf="loginDisplay && !getIsSession()">
                <button (click)="goToPage(1, 'meeting')">
                    {{getJoinTitle()}}
                </button>
            </tds-header-item>

            <tds-header-item [selected]="getSelected(1) ? 'true' : null" *ngIf="getIsSession()">
                <button (click)="goToPage(1, 'session')">
                    {{getJoinTitle()}}
                </button>
            </tds-header-item>

            <tds-header-item [selected]="getSelected(2) ? 'true' : null">
                <button (click)="goToPage(2, 'compare')">
                    {{getLanguageItem( 'navcompare' )}}
                </button>
            </tds-header-item>

            <tds-header-item [selected]="getSelected(3) ? 'true' : null" *ngIf="getIsAdmin()">
                <button (click)="goToPage(3, 'admin')">
                    {{getLanguageItem( 'navadmin' )}}
                </button>
            </tds-header-item>

            <tds-header-item [selected]="getSelected(0) ? 'true' : null">
                <button (click)="goToPage(0, 'home')">
                    {{getLanguageItem( 'navinfopage' )}}
                </button>
            </tds-header-item>

            <tds-header-item *ngIf="getIsSession()">
                <button (click)="leaveSession()">
                    {{getLanguageItem( 'navleave' )}}
                </button>
            </tds-header-item>

            <tds-header-dropdown slot="end" placement="end" no-dropdown-icon class="demo-hide demo-lg-show">
                <p slot="label">{{getCurrentLanguage()}}</p>
                <tds-header-dropdown-list type="sm">
                    <tds-header-dropdown-list-item [selected]="language.id == languageListService.languageId" *ngFor="let language of getPublishedLanguages()">
                        <a (click)="fetchLanguageItems(language.id)">{{language.fullName | titlecase}}</a>
                    </tds-header-dropdown-list-item>
                </tds-header-dropdown-list>
            </tds-header-dropdown>

            <tds-header-brand-symbol slot="end">
                <a aria-label="Scania - red gryphon on blue shield" href="https://www.scania.com/" target="_blank"></a>
            </tds-header-brand-symbol>
        </tds-header>
    </div>
    <div style="display: flex; flex-grow: 1; overflow-y: hidden;">
        <tds-side-menu
            aria-label="Side menu"
            open="{{ mobileNavOpen }}">
            <tds-side-menu-overlay
                slot="overlay"
                (click)="toggleMobileSideMenu()">
            </tds-side-menu-overlay>

            <tds-side-menu-close-button
                slot="close-button"
                (click)="toggleMobileSideMenu()">
            </tds-side-menu-close-button>

            <tds-side-menu-item [selected]="getSelected(1) ? 'true' : null" *ngIf="loginDisplay && !getIsSession()">
                <a (click)="toggleMobileSideMenu(); goToPage(1, 'meeting')">
                    <tds-icon
                        name="edit"
                        size="24">
                    </tds-icon>
                    {{getJoinTitle()}}
                </a>
            </tds-side-menu-item>

            <tds-side-menu-item [selected]="getSelected(1) ? 'true' : null" *ngIf="getIsSession()">
                <a (click)="toggleMobileSideMenu(); goToPage(1, 'session')">
                    <tds-icon
                        name="message"
                        size="24">
                    </tds-icon>
                    {{getJoinTitle()}}
                </a>
            </tds-side-menu-item>

            <tds-side-menu-item [selected]="getSelected(2) ? 'true' : null">
                <a (click)="toggleMobileSideMenu(); goToPage(2, 'compare')">
                    <tds-icon
                        name="speedometer"
                        size="24">
                    </tds-icon>
                    {{getLanguageItem( 'navcompare' )}}
                </a>
            </tds-side-menu-item>

            <tds-side-menu-item [selected]="getSelected(0) ? 'true' : null">
                <a (click)="toggleMobileSideMenu(); goToPage(0, 'home')">
                    <tds-icon
                        name="info"
                        size="24">
                    </tds-icon>
                    {{getLanguageItem( 'navinfopage' )}}
                </a>
            </tds-side-menu-item>

            <tds-side-menu-item *ngIf="getIsSession()">
                <a (click)="toggleMobileSideMenu(); leaveSession()">
                    <tds-icon
                        name="back"
                        size="24">
                    </tds-icon>
                    {{getLanguageItem( 'navleave' )}}
                </a>
            </tds-side-menu-item>

            <tds-side-menu-collapse-button slot="sticky-end"></tds-side-menu-collapse-button>
        </tds-side-menu>
    </div>
</div>

<section>
    <router-outlet></router-outlet>
</section>