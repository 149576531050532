<h5 mat-dialog-title>Confirm delete</h5>
<div mat-dialog-content>
  <p>Are you sure you want to delete?</p>
</div>
<div mat-dialog-actions align="center">
    <button mat-stroked-button 
        (click)="onYesClick()"
        title="Delete"
        style="margin-right: 8px;width: 75px;">Yes
    </button>
    <button mat-stroked-button 
    (click)="onNoClick()"
    title="Return without deleting"
    style="margin-right: 8px;width: 75px;">No
</button>
</div>