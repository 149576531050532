import { BrowserModule } from '@angular/platform-browser';
import { Injectable, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppRoutingModule } from './app-routing.module';

import {
    HTTP_INTERCEPTORS,
    HttpClientModule,
    HttpResponse,
    HttpHeaders
} from '@angular/common/http';
import {
    IPublicClientApplication,
    PublicClientApplication,
    InteractionType
} from '@azure/msal-browser';
import {
    MsalGuard,
    MsalInterceptor,
    MsalBroadcastService,
    MsalInterceptorConfiguration,
    MsalService,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalGuardConfiguration,
    MsalRedirectComponent
} from '@azure/msal-angular';
import { GroupGuardService } from './service/group-guard.service';
import {
    msalConfig,
    loginRequest,
    protectedResources
} from './auth-config';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTreeModule } from '@angular/material/tree';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SessionComponent } from './session/session.component';
import { CompareComponent } from './compare/compare.component';
import { AdminComponent } from './admin/admin.component';
import { InternalMsgService } from './service/internal.msg.service';
import { LanguageListService } from './service/languagelist.service';
import { DraggableDirective } from './directives/draggable.directive';
import { DroppableDirective } from './directives/droppable.directive';
import { MeetingComponent } from './meeting/meeting.component';


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    let url = window.location.host.split(':')[0];

    if (url === 'localhost') {
        protectedResourceMap
            .set(protectedResources.wbtApiLocal.endpoint, protectedResources.wbtApiLocal.scopes)
            .set(protectedResources.graphApi.endpoint, protectedResources.graphApi.scopes)
    }
    if (url === 'web.wbt.devtest.aws.scania.com') {
        protectedResourceMap
            .set(protectedResources.wbtApiDevTest.endpoint, protectedResources.wbtApiDevTest.scopes)
            .set(protectedResources.graphApi.endpoint, protectedResources.graphApi.scopes)
    }
    if (url === 'wbd.scania.com') {
        protectedResourceMap
            .set(protectedResources.wbtApiProd.endpoint, protectedResources.wbtApiProd.scopes)
            .set(protectedResources.graphApi.endpoint, protectedResources.graphApi.scopes)
    }

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: loginRequest
    };
}

@Injectable()
export class CustomRequestOptions extends HttpResponse<any> {
    constructor() { super(); }
    override headers = new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
    });
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        SessionComponent,
        CompareComponent,
        AdminComponent,
        DraggableDirective,
        DroppableDirective,
        MeetingComponent
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,

        MatAutocompleteModule,
        MatIconModule,
        MatInputModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatCardModule,
        MatFormFieldModule,
        MatDialogModule,
        MatTabsModule,
        MatTableModule,
        MatSortModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        MatButtonModule,
        MatPaginatorModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatListModule,
        MatCheckboxModule,
        MatTreeModule,
        MatSlideToggleModule,
        MatNativeDateModule,
        MatExpansionModule,
        MatProgressBarModule,
        ClipboardModule,
        DragDropModule,
        MatMenuModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        GroupGuardService,
        InternalMsgService,
        LanguageListService
    ],

    bootstrap: [AppComponent, MsalRedirectComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }