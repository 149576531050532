/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: '725644f1-02c6-4f0f-bba5-caefb7c1acd0', // This is the ONLY mandatory field that you need to supply.
    authority: 'https://login.microsoftonline.com/3bc062e4-ac9d-4c17-b4dd-3aad637ff1ac', // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: '/', // https://web.wbt.devtest.aws.scania.comPoints to window.location.origin. You must register this URI on Azure portal/App Registration.
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loadFrameTimeout: 6000,
    allowRedirectInIframe: true,
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        //console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false
    }
  }
}

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  wbtApiLocal: {
    endpoint: "https://localhost:44311/rest/v1",
    scopes: ["api://e6f275fc-9c7e-4a1c-a8ea-92f5a3fe0140/wbt-api.getpostdelete.any"],
  },
  wbtApiDevTest: {
    endpoint: "https://api.wbt.devtest.aws.scania.com/rest/v1",
    scopes: ["api://e6f275fc-9c7e-4a1c-a8ea-92f5a3fe0140/wbt-api.getpostdelete.any"],
  },
  wbtApiProd: {
    endpoint: "https://api.wbt.prod.aws.scania.com/rest/v1",
    scopes: ["api://e6f275fc-9c7e-4a1c-a8ea-92f5a3fe0140/wbt-api.getpostdelete.any"],
  },
  graphApi: {
    endpoint: "https://graph.microsoft.com/v1.0/me",
    scopes: ["User.Read"] //, "profile", "email"]
  }
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: []
};

export const groups = {
  groupDevelopers: "02b30022-6dec-4da9-967d-14589bfb65d7",
  groupTester: "2be9c235-6d93-408a-b08e-1d25d2fb3892",
  groupAdmin: "49ae0cac-2625-4adc-a2cb-8e536712bb60",
}
