import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { ReturnObject } from '../model/returnobject';
import { MeetingItem } from '../model/meetingitem';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeetingService {

    constructor( private api: APIService ) { }

    getMeetingsForUser(): Observable<ReturnObject<MeetingItem[]>> {
        return this.api.getAPI( `meeting/getmeetingsforuser` );
    }

    createMeeting(meetingName: string) {
        return this.api.postAPI( `meeting/createmeeting`, meetingName);
    }
}