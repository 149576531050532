<div class="wrapper">
    <div class="overlay" *ngIf="loading">
        <div class="spinner-wrapper">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div class="loaded-content" [class.blurred]="loading">
        <ng-content></ng-content>
    </div>
</div>

<!--ADD LANGUAGE-->
<div>
    <div class="tds-row" style="margin-top: 60px; display: flex; justify-content: center;">
        <div class="tds-col-xxlg-2 tds-col-xlg-2 tds-col-lg-2 tds-col-md-2 tds-col-sm-12 tds-col-xs-12">
            <h2>Add Language</h2>
        </div>
        <div class="tds-col-xxlg-4"></div>
    </div>

    <div class="tds-row" style="display: flex; justify-content: center;">
        <div class="tds-col-xxlg-2 tds-col-xlg-2 tds-col-lg-2 tds-col-md-2 tds-col-sm-12 tds-col-xs-12">
            <tds-text-field [(ngModel)]="shortLanguageName" ngDefaultControl type="Text" size="lg" state="default"
                label="Abbreviation" label-position="inside" no-min-width placeholder="Abbreviation">
            </tds-text-field>
        </div>
        <div class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-12 tds-col-xs-12">
            <tds-text-field [(ngModel)]="fullLanguageName" ngDefaultControl type="Text" size="lg" state="default"
                label="Full Name" label-position="inside" no-min-width placeholder="Full Name">
            </tds-text-field>
        </div>
    </div>

    <div style="margin-top: 20px; display: flex; justify-content: right">
        <div class="tds-col-xxlg-7 tds-col-xlg-7 tds-col-lg-7 tds-col-md-7 tds-col-sm-12 tds-col-xs-12">
            <tds-button type="button" variant="primary" size="md" text="Create" [disabled]="isCreateDisabled()"
                (click)="createLanguage()">
                <tds-icon slot="icon" size="20px" name="edit"></tds-icon>
            </tds-button>
        </div>
    </div>
</div>

<!--DIVIDER-->
<div style="margin-top: 40px; margin-bottom: 10px; display: flex; justify-content: center;">
    <div class="tds-col-xxlg-6 tds-col-xlg-6 tds-col-lg-6 tds-col-md-6 tds-col-sm-12 tds-col-xs-12">
        <tds-divider orientation="horizontal"></tds-divider>
    </div>
</div>

<!-- MANAGE LANGUAGE-->
<div>
    <div class="tds-row" style="display: flex; justify-content: center;">
        <div class="tds-col-xxlg-2 tds-col-xlg-2 tds-col-lg-2 tds-col-md-2 tds-col-sm-12 tds-col-xs-12">
            <h2>Manage Language</h2>
        </div>
        <div class="tds-col-xxlg-4"></div>
    </div>
    <div class="tds-row" style="display: flex; justify-content: center;">
        <div class="tds-col-xxlg-2 tds-col-xlg-2 tds-col-lg-2 tds-col-md-2 tds-col-sm-12 tds-col-xs-12">
            <mat-form-field>
                <mat-label>Select Language</mat-label>
                <mat-select id="selected" [(value)]="selected" (selectionChange)="getSelectionOnChange($event)">
                    <mat-option *ngFor="let language of getAdminLanguages()" value="{{language.id}}">
                        {{language.fullName | titlecase}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="tds-col-xxlg-4"></div>
    </div>

    <!--MODIFY LANGUAGE ACCORDIAN-->
    <div *ngIf="modifyLanguage" class="tds-row" style="display: flex; justify-content: center;">
        <div class="tds-col-xxlg-6 tds-col-xlg-6 tds-col-lg-6 tds-col-md-6 tds-col-sm-12 tds-col-xs-12">
            <mat-accordion>
                
                <!--EXISTING INFO PAGE HEADER/BODY TEXT-->
                <mat-expansion-panel (opened)="setOpenedLanugagePanel('infotexts', 'open')"
                    (closed)="closeInfoTextPanel()" [expanded]="panelOpenInfoTexts">

                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3>Infopage texts</h3>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div style="margin-bottom: 25px;">
                        <div style="color: darkred">
                            Note: Height of header and body text fields will expand as you type up to the allowed number
                            of character.
                        </div>
                    </div>

                    <div *ngFor="let languageItem of getInfopageItemHeaderList(); let i = index;">
                        <div [style]="(i%2==0 ? 'background-color:rgba(211, 211, 211, 0.459)' : '')">
                            <div class="tds-row" style="display: flex; justify-content: left;">
                                <div
                                    class="tds-col-xxlg-12 tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
                                    <h4>Info page text {{i + 1}}</h4>
                                </div>
                            </div>

                            <!--TEXTS-->
                            <div class="tds-row">
                                <div
                                    class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-12 tds-col-xs-12">
                                    <mat-form-field style="width:100%">
                                        <mat-label>Header (max 255 chars)</mat-label>
                                        <textarea matInput maxlength="255" id="{{languageItem.id}}" type="text"
                                            (change)="setHeader($event)"
                                            cdkTextareaAutosize>{{getHeader(languageItem.id)}}</textarea>
                                    </mat-form-field>
                                </div>

                                <div
                                    class="tds-col-xxlg-8 tds-col-xlg-8 tds-col-lg-8 tds-col-md-8 tds-col-sm-12 tds-col-xs-12">
                                    <mat-form-field style="width:100%">
                                        <mat-label>Body text (max 2000 chars)</mat-label>
                                        <textarea matInput maxlength="2000"
                                            id="{{getInfopageItemBodyId(languageItem.id)}}" type="text"
                                            (change)="setBody($event)"
                                            cdkTextareaAutosize>{{getInfopageItemBody(languageItem.key)}}</textarea>
                                    </mat-form-field>
                                </div>
                            </div>

                            <!--BUTTONS-->
                            <div class="tds-row">
                                <div
                                    class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-12 tds-col-xs-12">
                                </div>
                                <div class="tds-col-xxlg-8 tds-col-xlg-8 tds-col-lg-8 tds-col-md-8 tds-col-sm-12 tds-col-xs-12"
                                    style="display: flex; justify-content: left;">
                                    <button mat-stroked-button id="{{languageItem.id}}" color="primary" title="Update"
                                        (click)="updateInfopageItem($event)"
                                        style="margin-right: 8px;margin-bottom:10px">
                                        <span class="md-18 material-icons">upgrade</span> &nbsp;Update
                                    </button>
                                    <button mat-stroked-button id="deleteLanguageItem" name="{{languageItem.id}}"
                                        color="primary" title="Delete" (click)="openDialog($event)">
                                        <span class="md-18 material-icons">delete</span> &nbsp;Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="margin-top: 10px; display: flex; justify-content: center;">
                        <div
                            class="tds-col-xxlg-12 tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
                            <tds-divider orientation="horizontal"></tds-divider>
                        </div>
                    </div>

                    <!--NEW INFO PAGE HEADER + BODY TEXT-->
                    <div>
                        <div class="tds-row" style="display: flex; justify-content: left;">
                            <div
                                class="tds-col-xxlg-12 tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
                                <h4 style="text-align: left;">New info page text</h4>
                            </div>
                        </div>

                        <div class="tds-row">
                            <div
                                class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-12 tds-col-xs-12">
                                <mat-form-field style="width:100%">
                                    <mat-label>Header (max 255 chars)</mat-label>
                                    <input matInput maxlength="255" type="text" [(ngModel)]="newHeader"
                                        class="form-control" />
                                </mat-form-field>
                            </div>
                            <div
                                class="tds-col-xxlg-8 tds-col-xlg-8 tds-col-lg-8 tds-col-md-8 tds-col-sm-12 tds-col-xs-12">
                                <mat-form-field style="width:100%">
                                    <mat-label>Body (max 2000 chars)</mat-label>
                                    <textarea matInput maxlength="2000" type="text" [(ngModel)]="newBody"
                                        cdkTextareaAutosize></textarea>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="tds-row" style="margin-top: 10px; display: flex; justify-content: left;">
                            <div
                                class="tds-col-xxlg-12 tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
                                <button mat-stroked-button color="primary" title="Create" (click)="createInfopageItem()"
                                    style="margin-right: 8px;width: 120px;">
                                    <span class="md-18 material-icons">create</span> &nbsp;Create
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>

                <!--APPLICATION TEXTS-->
                <mat-expansion-panel (opened)="setOpenedLanugagePanel('apptexts', 'open')"
                    (closed)="closeAppTextPanel()" [expanded]="panelOpenAppTexts">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3>Application texts</h3>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div style="margin-bottom: 25px;">
                        <div>
                            Edit translation text for selected language ({{getSelLanguagName()}}).
                            <i style="color:darkred">Note: update happens automatically when pressing enter or leaving
                                edited field</i>
                        </div>
                    </div>

                    <div *ngFor="let languageItem of getEnglishItemList()">
                        <div class="tds-row" style="display: flex; justify-content: center;">
                            <div
                                class="tds-col-xxlg-6 tds-col-xlg-6 tds-col-lg-6 tds-col-md-6 tds-col-sm-12 tds-col-xs-12">
                                <mat-form-field style="width:100%">
                                    <mat-label>English</mat-label>
                                    <input matInput disabled value="{{languageItem.textString}}">
                                </mat-form-field>
                            </div>
                            <div
                                class="tds-col-xxlg-6 tds-col-xlg-6 tds-col-lg-6 tds-col-md-6 tds-col-sm-12 tds-col-xs-12">
                                <mat-form-field style="width:100%">
                                    <mat-label>Translation</mat-label>
                                    <input matInput maxlength="128" type="text" id="{{languageItem.key}}"
                                        (change)="setLanguageItem($event)"
                                        [value]="getTranslationItem(languageItem.key)" class="form-control">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
                
                <!--EXISTING INFO PAGE HEADER/BODY TEXT-->
                <mat-expansion-panel (opened)="setOpenedLanugagePanel('sessionquestionstexts', 'open')"
                    (closed)="closeSessionQuestionsTextPanel()" [expanded]="panelSessionQuestionsTexts">

                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3>Session Questions Texts</h3>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div style="margin-bottom: 25px;">
                        <div style="color: darkred">
                            Note: Height of header and body text fields will expand as you type up to the allowed number
                            of character.
                        </div>
                    </div>

                    <div *ngFor="let languageItem of getSessionpageQuestionsHeaderList(); let i = index;">
                        <div [style]="(i%2==0 ? 'background-color:rgba(211, 211, 211, 0.459)' : '')">
                            <div class="tds-row" style="display: flex; justify-content: left;">
                                <div
                                    class="tds-col-xxlg-12 tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
                                    <h4>Session question {{i + 1}}</h4>
                                </div>
                            </div>

                            <!--TEXTS-->
                            <div class="tds-row">
                                <div
                                    class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-12 tds-col-xs-12">
                                    <mat-form-field style="width:100%">
                                        <mat-label>Header (max 255 chars)</mat-label>
                                        <textarea matInput maxlength="255" id="{{languageItem.id}}" type="text"
                                            (change)="setHeader($event)"
                                            cdkTextareaAutosize>{{getHeader(languageItem.id)}}</textarea>
                                    </mat-form-field>
                                </div>

                                <div
                                    class="tds-col-xxlg-8 tds-col-xlg-8 tds-col-lg-8 tds-col-md-8 tds-col-sm-12 tds-col-xs-12">
                                    <mat-form-field style="width:100%">
                                        <mat-label>Body text (max 2000 chars)</mat-label>
                                        <textarea matInput maxlength="2000"
                                            id="{{getSessionpageQuestionsItemBodyId(languageItem.id)}}" type="text"
                                            (change)="setBody($event)"
                                            cdkTextareaAutosize>{{getSessionpageQuestionsItemBody(languageItem.key)}}</textarea>
                                    </mat-form-field>
                                </div>
                            </div>

                            <!--BUTTONS-->
                            <div class="tds-row">
                                <div
                                    class="tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-12 tds-col-xs-12">
                                </div>
                                <div class="tds-col-xxlg-8 tds-col-xlg-8 tds-col-lg-8 tds-col-md-8 tds-col-sm-12 tds-col-xs-12"
                                    style="display: flex; justify-content: left;">
                                    <button mat-stroked-button id="{{languageItem.id}}" color="primary" title="Update"
                                        (click)="updateSessionpageQuestionsItem($event)"
                                        style="margin-right: 8px;margin-bottom:10px">
                                        <span class="md-18 material-icons">upgrade</span> &nbsp;Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>

<!--LANGUAGE TEXTS-->
<div *ngIf="false">
    <!-- modifyLanguage"> -->
    <!--SELECT LANGUAGE TO MANAGE-->
    <div class="row">
        <div class="col-md-12">
            <div class="col-md-3"></div>
            <div class="col-md-2">
                <mat-form-field style="width:100%">
                    <mat-label>{{getLanguageFullName() | titlecase}}</mat-label>
                    <input matInput required maxlength="2000" type="text" placeholder="Full Name" class="form-control"
                        [(ngModel)]="updateFullLanguageName" />
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field style="width:100%">
                    <mat-label>{{getLanguageShortName() | uppercase}}</mat-label>
                    <input matInput required maxlength="2" type="text" placeholder="Abbreviation" class="form-control"
                        [(ngModel)]="updateShortLanguageName" />
                </mat-form-field>
            </div>
            <div class="col-md-3"></div>
        </div>

        <div class="col-md-12" style="margin-bottom: 10px;">
            <div class="col-md-5"></div>
            <div class="col-md-4" style="text-align: left">
                <mat-slide-toggle [checked]="languagePublished" [(ngModel)]="languagePublished">
                    {{(languagePublished==true ? 'Language is published' : 'Language is not published')}}
                </mat-slide-toggle>
            </div>
            <div class="col-md-3"></div>
        </div>

        <div class="col-md-12">
            <div class="col-md-5"></div>
            <div class="col-md-4" style="text-align: left">
                <button mat-stroked-button color="primary" (click)="updateLanguage()" title="Update"
                    style="margin-right: 8px ;">
                    <span class="md-18 material-icons">upgrade</span> &nbsp;Update
                </button>
                <button mat-stroked-button id="deleteLanguage" color="primary" (click)="openDialog($event)"
                    title="Delete">
                    <span class="md-18 material-icons">delete</span> &nbsp;Delete
                </button>
            </div>
            <div class="col-md-3"></div>
        </div>
    </div>

    <!--DIVIDER-->
    <div class="col-md-12" style="margin-top: 40px;margin-bottom: 20px;">
        <div class="col-md-3"></div>
        <div class="col-md-6" style="border-bottom: 1px solid darkgrey;"></div>
        <div class="col-md-3"></div>
    </div>


    <!--INFO PAGE TEXTS-->
    <div>

    </div>


    <!--DIVIDER-->
    <div class="col-md-12" style="margin-top: 40px;margin-bottom: 20px;">
        <div class="col-md-3"></div>
        <div class="col-md-6" style="border-bottom: 3px solid darkgrey;"></div>
        <div class="col-md-3"></div>
    </div>


    <!--APPLICATIONTEXTS-->
    <div>
        <div class="col-md-12" style="margin-bottom: 25px;">
            <div class="col-md-3"></div>
            <div class="col-md-9">
                <h4 style="text-align: left;">Application texts</h4>
            </div>
            <div class="col-md-3"></div>
            <div class="col-md-6">
                Edit translation text for selected language ({{getSelLanguagName()}}).
                <i style="color:darkred">Note: update happens automatically when pressing enter or leaving edited
                    field</i>
            </div>
            <div class="col-md-3"></div>
        </div>

        <div *ngFor="let languageItem of getEnglishItemList(); let i = index;">
            <div class="col-md-12" style="margin-top: 5px;">
                <div class="col-md-3"></div>
                <div class="col-md-6" style="padding-top: 15px;"
                    [style]="(i%2==0 ? 'background-color:rgba(211, 211, 211, 0.459)' : '')">
                    <div class="col-md-6">
                        <mat-form-field style="width:100%">
                            <mat-label>English</mat-label>
                            <input matInput disabled value="{{languageItem.textString}}">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field style="width:100%">
                            <mat-label>Translation</mat-label>
                            <input matInput maxlength="128" type="text" id="{{languageItem.key}}"
                                (change)="setLanguageItem($event)" [value]="getTranslationItem(languageItem.key)"
                                class="form-control" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-3"></div>
            </div>
        </div>
    </div>
</div>