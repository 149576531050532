import { Injectable } from '@angular/core';
import { User } from '../model/user';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private userkey: string = '';
    
    constructor ()   {}

    setUser( user: User ) { 
        this.userkey = user.userKey;
        localStorage.setItem('user', JSON.stringify( user ) ); 
    }

    getUser(): User {
        let usr:any = localStorage.getItem('user'); 
        if ( usr === undefined || usr === null  ) {
            return User.getEmpty();
        } else {
            return JSON.parse( usr );
        }
    }    

    getUserForHeader() {
        let usr = this.getUser();
        var user: User = {
            name: usr.name,
            id: usr.id,
            accessFeatures: usr.accessFeatures,
            department: usr.department,
            role: usr.role,
            userKey: usr.userKey,
            azureUserName: usr.azureUserName
        };
        return usr;
    }

    setAzureUserName(azureUserName?:string) {
        let usr = this.getUser();
        usr.azureUserName = azureUserName ? azureUserName : '';
        localStorage.setItem('user', JSON.stringify(usr) ); 
    }


    setGlobalID(id:string) {
        let usr = this.getUser();
        usr.id = id;
        localStorage.setItem('user', JSON.stringify(usr) ); 
    }    

    isAdminUSer() {
        return this.getUser().accessFeatures.includes('sysadmin');
    }

    isStatUser() {
        return this.getUser().accessFeatures.includes('statistics');
    }

    logout() {
        localStorage.removeItem( 'user' );
        localStorage.removeItem( "userkey" );
    }    
}