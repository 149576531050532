import { Injectable } from '@angular/core';
import { Language } from '../model/language';
import { LanguageAdmin } from '../model/languageadmin';
import { LanguageItem } from '../model/languageitem';

@Injectable( {
    providedIn: 'root'
} )
export class SettingsService {
    private language: Language = null;
    private languagePrivileges: LanguageAdmin[] = [];
    private isAdmin: boolean;
    private isSession: boolean = false;
    private isModerator: boolean = false;
    private meetingKey: string = null;
    private moderatorKey: string = null;
    private sessionDate: string = null;
    private userId: string = null;
    private sessionId: number = null;
    private selectedAdminLanguge: string = null;
    private openedLanguagePanel: string = "";
    private englishLanguageItemList: LanguageItem[] = null;
    private translationLanguageItemList: LanguageItem[] = null;

    constructor ()   {}

    getSelectedAdminLanguage() {
        this.selectedAdminLanguge = JSON.parse( localStorage.getItem( 'selectedAdminLanguge' ) );
        if ( !this.selectedAdminLanguge ) {
            this.selectedAdminLanguge = "2";
            this.setSelectedAdminLanguage( "2 ");
        }        
        return this.selectedAdminLanguge.toString();
    }

    setSelectedAdminLanguage(languageId: string) {
        localStorage.setItem( 'selectedAdminLanguge', languageId );
        this.selectedAdminLanguge = languageId;
    }

    getOpenedLanugagePanel(){
        return this.openedLanguagePanel;
    }

    setAdminLangEnglish(englishLanguageItemList: LanguageItem[]){
        this.englishLanguageItemList = englishLanguageItemList;
    }
    
    setAdminLangTranslate(translationLanguageItemList: LanguageItem[]){
        this.translationLanguageItemList = translationLanguageItemList;
    }

    getAdminLangEnglish(){
        return this.englishLanguageItemList;
    }

    getAdminLangTranslate(){
        return this.translationLanguageItemList;
    }

    setOpenedLanugagePanel(panelName: string ){
        this.openedLanguagePanel = panelName;
    }

    getLanguagePrivileges(){
        return this.languagePrivileges;
    }

    setLanguagePrivileges(languagePrivileges: LanguageAdmin[]){
        this.languagePrivileges = languagePrivileges;
    }

    getIsAdmin(){
        return this.isAdmin;
    }

    setIsAdmin(isAdmin: boolean){
        this.isAdmin = isAdmin;
    }

    getSessionId(){
        if ( !this.sessionId ) {
            this.sessionId = JSON.parse( sessionStorage.getItem( 'sessionId' ) );
        }
        return this.sessionId; 
    }

    setSessionId(sessionId: number){
        this.sessionId = sessionId;
        if (sessionId) {
            sessionStorage.setItem( 'sessionId', JSON.stringify( sessionId ) );
        } else {
            sessionStorage.removeItem('sessionId');
        }
    }

    getUserId(){
        return this.userId;
    }

    setUserId(userId: string) {
        this.userId = userId;
    }

    getLanguage() {
        if ( !this.language ) {
            this.language = JSON.parse( localStorage.getItem( 'language' ) );
        }
        return this.language;
    }

    setLanguage( language: Language ) {
        this.language = language;
        localStorage.setItem( 'language', JSON.stringify( language ) );
    }
    
    getSessionDate() {
        return this.sessionDate;
    }

    setSessionDate( sessionDate: string ) {
        this.sessionDate = sessionDate;
        if (sessionDate) {
            sessionStorage.setItem( 'sessionDate', JSON.stringify( sessionDate ) );
        } else {
            sessionStorage.removeItem('sessionDate');
        } 
    }

    getIsSession() {
        var isSession = this.isSession && this.getIsSessionDate();
        return isSession;
    }

    setIsSession( isSession: boolean ) {
        this.isSession = isSession;
        if (isSession) {
            sessionStorage.setItem( 'isSession', JSON.stringify( isSession ) );
        } else {
            sessionStorage.removeItem('isSession');
        }        
    }

    getIsModerator() {
        return this.isModerator && this.getIsSessionDate();
    }

    setIsModerator( isModerator: boolean ) {
        this.isModerator = isModerator;
        if (isModerator) {
             sessionStorage.setItem( 'isModerator', JSON.stringify( isModerator ) );
        } else {
            sessionStorage.removeItem('isModerator');
        } 
    }

    getMeetingKey() {
        return this.meetingKey;
    }

    setMeetingKey( meetingKey: string ) {
        this.meetingKey = meetingKey;
        if (meetingKey) {
            sessionStorage.setItem( 'meetingKey', JSON.stringify( meetingKey ) );
        } else {
            sessionStorage.removeItem('meetingKey');
        }
    }

    setMeetingKeyLocal( meetingKey: string ) {
        this.meetingKey = meetingKey;
        if (meetingKey) {
            localStorage.setItem('meetingKey', JSON.stringify(meetingKey));
        } else {
            localStorage.removeItem('meetingKey');
        }
    }

    getModeratorKey() {
        return this.moderatorKey;
    }

    setModeratorKey( moderatorKey: string ) {
        this.moderatorKey = moderatorKey;
        if (moderatorKey) {
            sessionStorage.setItem( 'moderatorKey', JSON.stringify( moderatorKey ) );
        } else {
            sessionStorage.removeItem('moderatorKey');
        }
    }

    setModeratorKeyLocal(moderatorKey: string ) {
        this.moderatorKey = moderatorKey;
        if (moderatorKey) {
            localStorage.setItem('moderatorKey', JSON.stringify(moderatorKey));
        } else {
            localStorage.removeItem('moderatorKey');
        }
    }

    private getIsSessionDate() {
        let sessionDate= JSON.parse( sessionStorage.getItem( 'sessionDate' ) );
        return (sessionDate && sessionDate === this.getCurDateString())
    }    
    
    initSession() {
        if (this.getIsSessionDate())
        {
            this.isSession = JSON.parse( sessionStorage.getItem( 'isSession' ) );
            this.isModerator = JSON.parse( sessionStorage.getItem( 'isModerator' ) );
            this.meetingKey = JSON.parse( sessionStorage.getItem( 'meetingKey' ) );
            this.moderatorKey = JSON.parse( sessionStorage.getItem( 'moderatorKey' ) );
            this.sessionDate = JSON.parse( sessionStorage.getItem( 'sessionDate' ) );
        } else {
            this.setIsModerator( false );
            this.setIsSession( false );
            this.setSessionDate( null );
            this.setModeratorKey( null );
            this.setMeetingKey( null );
        }
    }

    private getCurDateString() : string {
        let today = new Date();
        var month =  (today.getMonth() + 1).toString().padStart(2, '0');
        var day =  today.getDate().toString().padStart(2, '0');
        
        return `${today.getFullYear().toString()}-${month}-${day}`;
    }

    leaveSession(){
        this.setIsSession(false);
        this.setIsModerator(false);
        this.setMeetingKey( null );
        this.setModeratorKey( null );
        this.setSessionDate( null );
        this.setSessionId( null );
        this.setMeetingKeyLocal( null );
        this.setModeratorKeyLocal( null );
    }

    logout() {
        localStorage.removeItem( "menues" ); // local
        this.leaveSession();
    }
}