import { Component, OnInit } from '@angular/core';
import { InternalMsgService } from '../service/internal.msg.service';
import { LanguageListService } from '../service/languagelist.service';
import { Subscription } from 'rxjs';
import { LanguageItem } from '../model/languageitem';
import { MatSnackBar } from '@angular/material/snack-bar';
import { errorCopy } from '../common/errorCopy';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    static snackBarErrMsg: string = '';
    languageSubscr: Subscription;
    languageItemList: LanguageItem[] = [];

    panelOpenState = false;
    constructor(private msgService: InternalMsgService,
        private languageListService: LanguageListService,
        private snackBar: MatSnackBar,
        private errCopy: errorCopy) {
    }

    ngOnInit(): void {
        this.msgService.onSelectedMenuSet(0);
        this.initServices();
    }

    initServices() {
        this.languageSubscr = this.languageListService.languageitemlist$.subscribe(words => {
            if (this.languageListService.listErrorMsg != '') {
                this.openSnackBarErr(this.languageListService.listErrorMsg);
                this.languageListService.listErrorMsg = '';
            }
            this.languageItemList = words;
        });
        this.languageListService.fetchData();
    }

    getHeaderItems() {
        if (this.languageItemList.length != 0) {
            return this.languageItemList.filter(x => x.group == "home" && x.key.startsWith("header"));
        }
        return new LanguageItem;
    }

    getBodyItems(key: string) {
        var body = "body" + key.slice(6);
        if (this.languageItemList.find(x => x.key == body)) {
            return this.languageItemList.find(x => x.key == body).textString;
        }
        return new LanguageItem;
    }

    getIsLoading() {
        return this.languageListService.loading;
    }

    openSnackBarInfo(message: string) {
        this.snackBar.open(message, 'close', {
            duration: 7000,
            panelClass: 'snackbar_info'
        });
    }

    getLanguageItem(item: string) {
        if (this.languageItemList.length != 0)
            if (this.languageItemList.find(x => x.key == item))
                return this.languageItemList.find(x => x.key == item).textString;
        return "...";
    }

    openSnackBarErr(message: string) {
        HomeComponent.snackBarErrMsg = 'An unexpected error occurred. The error was: ' + message;
        let snRef = this.snackBar.open(message, 'Copy and close', {
            duration: 20000,
            panelClass: 'snackbar_error'
        });

        this.errCopy.snackBarCopy(snRef, document, HomeComponent.snackBarErrMsg);
    }
}
