import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { ReturnObject } from '../model/returnobject';
import { Observable } from 'rxjs';
import { MeetingItem } from '../model/meetingitem';
import { SessionItem } from '../model/sessionitem';
import { SessionUser } from '../model/sessionuser';
@Injectable( {
    providedIn: 'root'
} )
export class CompareService {
 
    constructor( private api: APIService) {
    }

    getMeetingList(): Observable<ReturnObject<MeetingItem[]>> {
        return this.api.getAPI( `compare/meetinglist` );
    }

    getSessions(meetingId: number): Observable<ReturnObject<SessionItem[]>> {
        return this.api.getAPI( `compare/sessions/${meetingId}` );
    }

    getParticipants(sessionId: number): Observable<ReturnObject<SessionUser[]>> {
        return this.api.getAPI( `compare/sessiondetail/${sessionId}` );
    }
}