import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import { AccountInfo } from '@azure/msal-common';
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { SettingsService } from './settings.service';
import { groups } from '../auth-config';
import { InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';

interface Account extends AccountInfo {
    idTokenClaims?: {
        roles?: string[],
        groups?: string[],
        _claim_names?: {
            groups: string | string[]
        },
        _claim_sources?: {
            src1: {
                endpoint: string | string[]
            }
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class GroupGuardService implements CanActivate {

    constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        
        private settingsService: SettingsService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        let account: Account = this.authService.instance.getAllAccounts()[0];
        
        if (route.params['meetingkey'] !== null && route.params['meetingkey'] !== undefined && route.params['meetingkey'] !== '') {
            localStorage.setItem('meetingKey', route.params['meetingkey']);
        }
        if (route.params['moderatorkey'] !== null && route.params['moderatorkey'] !== undefined && route.params['moderatorkey'] !== '') {
            localStorage.setItem('moderatorKey', route.params['moderatorkey']);
        }

        if (!account) {
            this.login();
        }

        this.settingsService.setIsAdmin(account.idTokenClaims?.groups?.includes(groups.groupAdmin)); // account.idTokenClaims?.roles.indexOf('language.admin') != -1)

        if (route.data['expectedRole'] === 'admin') {
            if (!this.settingsService.getIsAdmin()) {
                window.alert('You do not have access for this.');
                return false;
            }
        }

        if (this.IsDevEnvironment && account.idTokenClaims?.groups?.includes(groups.groupDevelopers)) {
            return true;
        }

        if (this.IsTestEnvironment && account.idTokenClaims?.groups?.includes(groups.groupTester)) {
            return true;
        }

        if (this.IsProdEnvironment) {
            return true;
        }

        window.alert('You do not have access for this.');
        return false;
    }

    login() {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            if (this.msalGuardConfig.authRequest) {
                this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
                    .subscribe(() => alert('this.checkAccount())'));
            }
            else {
                this.authService.loginPopup()
                    .subscribe(() => alert('this.checkAccount())'));
            }
        } else {
            if (this.msalGuardConfig.authRequest) {
                this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
            } else {
                this.authService.loginRedirect();
            }
        }
    }

    public get IsDevEnvironment(): boolean {
        return window.location.host.startsWith('localhost:4200');
    }

    public get IsTestEnvironment(): boolean {
        return window.location.host.startsWith('web.wbt.devtest.aws.scania.com');
    }

    public get IsProdEnvironment(): boolean {
        return window.location.host.startsWith('wbd.scania.com');
    }
}