import { Injectable } from '@angular/core';
@Injectable( {
    providedIn: 'root'
} )
export class errorCopy {
    
    snackBarCopy(snRef: any, document: Document, snackBarErrMsg: string) {
        const ele = document.createElement('input'); 
        snRef.onAction().subscribe(() => {
            ele.value = snackBarErrMsg; 
            ele.setAttribute('readonly', 'true')
            // Following styling is to avoid flashing textarea on screen 
            ele.style.position = 'absolute';
            ele.style.background = 'transparent';
            ele.style.outline = 'none'; 
            ele.style.left = '-100%';
            document.body.appendChild(ele); 
            ele.select(); 

            // do the copy to clipboard and remove the temp input control
            document.execCommand('copy');
            document.body.removeChild(ele); 
            snackBarErrMsg = '';

            snRef.dismiss();
        });        
    }
}