import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class InternalMsgService {

    // Observable string sources

    private selectedMenuSource = new Subject<number>();
    selectedMenuSet$ = this.selectedMenuSource.asObservable();

    onSelectedMenuSet( index: number ) {
        this.selectedMenuSource.next( index );
    }

    private sessionLeaveSource = new Subject<boolean>();
    sessionLeaveSet$ = this.sessionLeaveSource.asObservable();

    onSessionLeaveSet( leave: boolean ) {
        this.sessionLeaveSource.next( leave );
    }
}