<div class="tds-row" style="margin-bottom: 40px;">
    <div class="tds-col-xxlg-3 tds-col-xlg-3 tds-col-lg-3 tds-col-md-0 tds-col-sm-0 tds-col-xs-0"></div>

    <div class="tds-col-xxlg-6 tds-col-xlg-6 tds-col-lg-6 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
        <div class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12"
            style="text-align: center;">
            <h4>{{getLanguageItem( 'headercreatemeeting' )}}</h4>
        </div>
        <div class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12"
            style="margin-top: 20px; display: flex; justify-content: center;">
            <tds-text-field style="width: 400px;" [(ngModel)]="meetingName" ngDefaultControl type="Text" size="lg"
                state="default" label="{{getLanguageItem( 'titlemeetingname' )}}" label-position="inside" no-min-width
                placeholder="{{getLanguageItem( 'titlemeetingname' )}}">
            </tds-text-field>
        </div>
        <div class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12"
            style="margin-top: 20px; display: flex; justify-content: center;">
            <tds-button type="button" variant="primary" size="md" text="{{getLanguageItem( 'titlecreate' )}}"
                [disabled]="isCreateDisabled()" (click)="createMeeting()">
                <tds-icon slot="icon" size="20px" name="edit"></tds-icon>
            </tds-button>
        </div>
        <div *ngIf="myMeetings.length > 0">
            <div class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12"
                style="margin-top: 20px">
                <tds-divider orientation="horizontal"></tds-divider>
            </div>
            <div class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12"
                style="text-align: center;">
                <h2>{{getLanguageItem( 'headermymeetings' )}}</h2>
            </div>
            <div class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12"
                style="text-align: center;">
                <h4>{{getLanguageItem( 'headercopyparticipantlink' )}}</h4>
            </div>
            <div class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12"
                style="text-align: center;">
                <h4>{{getLanguageItem( 'headerstartmeetingbuttonorcopymoderatorlink' )}}</h4>
            </div>
            <div id="content-desktop" class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Meeting Name </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.name}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="startMeetingButton">
                        <th mat-header-cell *matHeaderCellDef> Start Button </th>
                        <td mat-cell *matCellDef="let element">
                            <tds-button type="button" variant="secondary" size="sm" text="{{getLanguageItem( 'titlestartmeetingbutton' )}}" (click)="startMeeting(element.key, element.moderatorKey)">
                                <tds-icon slot="icon" size="16px" name="send"></tds-icon>
                            </tds-button>
                            </td>
                    </ng-container>

                    <ng-container matColumnDef="key">
                        <th mat-header-cell *matHeaderCellDef> Meeting Link </th>
                        <td mat-cell *matCellDef="let element">
                            <a style="cursor: pointer;" (click)="tooltipCopy.show()"
                                matTooltip="{{getLanguageItem( 'titlecopy' )}}"
                                cdkCopyToClipboard="{{ createMeetingLink(element.key) }}">
                                {{getLanguageItem( 'titleparticipantlink' )}}
                                <span #tooltipCopy="matTooltip" matTooltip="{{getLanguageItem( 'copied' )}}"></span>
                            </a>
                            <button mat-icon-button aria-label="Copy meeting link button" (click)="tooltipCopy2.show()"
                                matTooltip="{{getLanguageItem( 'titlecopy' )}}"
                                cdkCopyToClipboard="{{ createMeetingLink(element.key) }}">
                                <mat-icon>content_copy</mat-icon>
                                <span #tooltipCopy2="matTooltip" matTooltip="{{getLanguageItem( 'copied' )}}"></span>
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="moderatorKey">
                        <th mat-header-cell *matHeaderCellDef> Moderator Link </th>
                        <td mat-cell *matCellDef="let element">
                            <a style="cursor: pointer;" (click)="tooltipCopy3.show()"
                                matTooltip="{{getLanguageItem( 'titlecopy' )}}" 
                                cdkCopyToClipboard="{{ createModeratorLink(element.key, element.moderatorKey) }}">
                                {{getLanguageItem( 'titlemoderatorlink' )}}
                                <span #tooltipCopy3="matTooltip" matTooltip="{{getLanguageItem( 'copied' )}}"></span>
                            </a>
                            
                            <button mat-icon-button aria-label="Copy moderator link button"
                                (click)="tooltipCopy4.show()" matTooltip="{{getLanguageItem( 'titlecopy' )}}"
                                cdkCopyToClipboard="{{ createModeratorLink(element.key, element.moderatorKey) }}">
                                <mat-icon>content_copy</mat-icon>
                                <span #tooltipCopy4="matTooltip" matTooltip="{{getLanguageItem( 'copied' )}}"></span>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <div id="content-mobile" class="tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Meeting Name </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.name}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="startMeetingButton">
                        <th mat-header-cell *matHeaderCellDef> Start Meeting </th>
                        <td mat-cell *matCellDef="let element">
                            <tds-button type="button" variant="secondary" size="sm" text="" (click)="startMeeting(element.key, element.moderatorKey)">
                                <tds-icon slot="icon" size="16px" name="send"></tds-icon>
                            </tds-button>
                            </td>
                    </ng-container>

                    <ng-container matColumnDef="key">
                        <th mat-header-cell *matHeaderCellDef> Meeting Link </th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button aria-label="Copy meeting link button" (click)="tooltipCopy2.show()"
                                matTooltip="{{getLanguageItem( 'titlecopy' )}}"
                                cdkCopyToClipboard="{{ createMeetingLink(element.key) }}">
                                <mat-icon>content_copy</mat-icon>
                                <span #tooltipCopy2="matTooltip" matTooltip="{{getLanguageItem( 'copied' )}}"></span>
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="moderatorKey">
                        <th mat-header-cell *matHeaderCellDef> Moderator Link </th>
                        <td mat-cell *matCellDef="let element">    
                            <button mat-icon-button aria-label="Copy moderator link button"
                                (click)="tooltipCopy4.show()" matTooltip="{{getLanguageItem( 'titlecopy' )}}"
                                cdkCopyToClipboard="{{ createModeratorLink(element.key, element.moderatorKey) }}">
                                <mat-icon>content_copy</mat-icon>
                                <span #tooltipCopy4="matTooltip" matTooltip="{{getLanguageItem( 'copied' )}}"></span>
                            </button>
                        </td>
                    </ng-container>
                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
    <div class="tds-col-xxlg-3 tds-col-xlg-3 tds-col-lg-3 tds-col-md-0 tds-col-sm-0 tds-col-xs-0"></div>
</div>