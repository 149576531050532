import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ReturnObject } from '../model/returnobject';
import { SessionService } from '../service/session.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionUser } from '../model/sessionuser';
import { MeetingItem } from '../model/meetingitem';
import { Subscription } from 'rxjs';
import { LanguageListService } from '../service/languagelist.service';
import { InternalMsgService } from '../service/internal.msg.service';
import { LanguageItem } from '../model/languageitem';
import { SettingsService } from '../service/settings.service';
import { Faces } from '../interface/face-interface';
import { errorCopy } from '../common/errorCopy';
import { Router, ActivatedRoute } from '@angular/router';
import { MeetingService } from '../service/meeting.service';

const XLIMIT = 400;
const YLIMIT = 370;

@Component({
    selector: 'app-session',
    templateUrl: './session.component.html',
    styleUrls: ['./session.component.css']
})
export class SessionComponent implements OnInit, OnDestroy {
    loading: boolean = false;
    loadingSession: boolean = false;
    panelMyFacesOpen: boolean = false;

    static snackBarErrMsg: string = '';
    meetingKey: string = "";
    moderatorKey: string = "";
    meetingName: string = "";
    generatedMeetingKey: string = "";
    generatedModeratorKey: string = "";
    droppedFace: string = "";
    circleTitle: string = "";

    meetingKeyIn: string = "";
    moderatorKeyIn: string = "";

    languageSubscr: Subscription;
    currentUser: SessionUser = new SessionUser(null);
    meeting: MeetingItem = new MeetingItem();
    interval: any;
    sessionsList: string[] = [];
    languageItemList: LanguageItem[] = [];
    participants: SessionUser[] = [];
    iterationList: number[] = [6, 12, 17, 22, 26, 33, 38, 43, 47, 50];
    sections: string[] = ["manageability", "comprehensibility", "meaningfulness", "recovery"];
    faces: Faces[] = [
        {
            value: 'excellent',
            label: 'excellentface',
            img: "../../assets/excellent.png",
            myImg: "../../assets/myexcellent.png",
            modImg: "../../assets/modexcellent.png"
        },
        {
            value: 'good',
            label: 'goodface',
            img: "../../assets/good.png",
            myImg: "../../assets/mygood.png",
            modImg: "../../assets/modgood.png"
        },
        {
            value: 'insufficient',
            label: 'insufficientface',
            img: "../../assets/insufficient.png",
            myImg: "../../assets/myinsufficient.png",
            modImg: "../../assets/modinsufficient.png"
        },
        {
            value: 'poor',
            label: 'poorface',
            img: "../../assets/poor.png",
            myImg: "../../assets/mypoor.png",
            modImg: "../../assets/modpoor.png"
        }
    ];

    constructor(private sessionService: SessionService,
        private meetingService: MeetingService,
        private settingsService: SettingsService,
        private msgService: InternalMsgService,
        private languageListService: LanguageListService,
        private snackBar: MatSnackBar,
        private router: Router,
        private errCopy: errorCopy,
        private route: ActivatedRoute) { }

    ngOnDestroy(): void {
        this.languageSubscr.unsubscribe();
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            if (params.get('meetingkey') !== null) {
                this.meetingKeyIn = params.get('meetingkey');
            }
            if (params.get('moderatorkey') !== null) {
                this.moderatorKeyIn = params.get('moderatorkey');
            }
            this.msgService.onSelectedMenuSet(1);
            this.initServices();
        });
    }

    initServices() {
        this.languageSubscr = this.languageListService.languageitemlist$.subscribe(
            words => {
                this.languageItemList = words;
            });

        this.msgService.sessionLeaveSet$.subscribe(
            leave => {
                if (leave) {
                    clearInterval(this.interval);
                    this.router.navigate([`/meeting`]);
                }
            });

        this.languageListService.fetchData();
        if (this.settingsService.getIsSession()) {
            this.meetingKey = this.settingsService.getMeetingKey();
            this.moderatorKey = this.settingsService.getModeratorKey();
            this.joinSession();
        } else if (this.meetingKeyIn != "") {
            this.meetingKey = this.meetingKeyIn;
            this.moderatorKey = this.moderatorKeyIn;
            this.joinSession();
        }
    }

    joinSession() {
        this.settingsService.setMeetingKey(this.meetingKey);
        this.settingsService.setModeratorKey(this.moderatorKey);
        var moderator = !this.moderatorKey || this.moderatorKey.trim().length != 32 ? '-' : this.moderatorKey;
        this.loadingSession = true;
        this.sessionService.joinSession(this.meetingKey, moderator)
            .subscribe({
                next: (result: ReturnObject<SessionUser>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        this.loadingSession = false;
                        this.router.navigate([`/meeting`]);
                        return;
                    }

                    this.currentUser = new SessionUser(result.value);
                    this.meetingName = result.value.meetingName;

                    this.settingsService.setIsSession(true);
                    this.settingsService.setSessionDate(result.value.sessionDate);
                    this.settingsService.setSessionId(result.value.sessionId);

                    this.interval = setInterval(() => { this.getParticipants() }, 15000);
                    this.settingsService.setIsModerator(moderator.length == 32);
                    this.loadingSession = false;

                    this.getParticipants();
                },
                error: (err) => {
                    this.loadingSession = false;
                    this.openSnackBarErr(err.message);
                }
            });
    }

    addUserToSession(user: SessionUser) {
        this.loadingSession = true;
        this.sessionService.addUserToSession(user)
            .subscribe({
                next: (result: ReturnObject<SessionUser>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        this.loadingSession = false;
                        return;
                    }
                    this.getParticipants();
                    this.loadingSession = false;
                },
                error: (err) => {
                    this.loadingSession = false;
                    this.openSnackBarErr(err.message);
                }
            });
    }

    createMeeting() {
        this.loadingSession = true;
        this.meetingService.createMeeting(this.meetingName)
            .subscribe({
                next: (result: ReturnObject<MeetingItem>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        this.loadingSession = false;
                        return;
                    }
                    this.meeting = result.value;
                    this.generatedMeetingKey = this.meeting.key;
                    this.generatedModeratorKey = this.meeting.moderatorKey;
                    this.loadingSession = false;
                },
                error: (err) => {
                    this.loadingSession = false;
                    this.openSnackBarErr(err.message);
                }
            });
    }

    getParticipants() {
        this.loadingSession = true;
        this.sessionService.getSessionList(this.settingsService.getSessionId())
            .subscribe({
                next: (result: ReturnObject<SessionUser[]>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        this.loadingSession = false;
                        return;
                    }
                    if (result.value) {
                        this.participants = [];
                        result.value.forEach(element => {
                            this.participants.push(new SessionUser(element));
                        });
                        this.setXY();
                    }
                    setTimeout(() => {
                        this.loadingSession = false;
                    }
                        , 1000)
                },
                error: (err) => {
                    this.openSnackBarErr(err.message);
                    this.loadingSession = false;
                }
        });
    }

    postUserToDb(user: SessionUser) {
        this.loadingSession = true;
        this.sessionService.postUserToDb(user)
            .subscribe({
                next: (result: ReturnObject<SessionUser>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        this.loadingSession = false;
                        return;
                    }
                    this.getParticipants();

                    setTimeout(() => {
                        this.loadingSession = false;
                    }, 1000)
                },
                error: (err) => {
                    this.openSnackBarErr(err.message);
                    this.loadingSession = false;
                }
            });
    }

    deleteUserFromDb(user: SessionUser) {
        this.loadingSession = true;
        this.sessionService.deleteUserFromDb(user.detailId)
            .subscribe({
                next: (result: ReturnObject<SessionUser>) => {
                    if (result.isFailure) {
                        this.openSnackBarErr(result.failureMessage);
                        this.loadingSession = false;
                        return;
                    }
                    this.getParticipants();

                    setTimeout(() => {
                        this.loadingSession = false;
                    }, 1000)
                },
                error: (err) => {
                    this.loadingSession = false;
                    this.openSnackBarErr(err.message);
                }
            });
    }

    setDroppedFace(coordinateX: number, coordinateY: number) {
        var section = this.getSectionFromCoordinates(coordinateX, coordinateY);
        if (this.settingsService.getIsModerator()) {
            this.setFaceModerator(this.droppedFace, section);
        }
        else {
            this.currentUser.setSectionValue(section, this.droppedFace);
            this.postUserToDb(this.currentUser);
        }
    }

    setFaceMobile() {
        this.postUserToDb(this.currentUser);
    }

    setFaceModerator(value: string, section: string) {
        if (this.currentUser.getSectionValue(section) == "") {
            this.currentUser.setSectionValue(section, value);
            this.currentUser.isModeratorRecord = true;
            this.currentUser.actingAsModerator = true;
            this.postUserToDb(this.currentUser);
        }
        else if (this.participants.find(x => x.userKey == this.currentUser.userKey && x.getSectionValue(section) == "")) {
            var user = this.participants.find(x => x.userKey == this.currentUser.userKey && x.getSectionValue(section) == "");
            user.setSectionValue(section, value);
            this.postUserToDb(user);
        }
        else {
            var newSessionUser = new SessionUser(null);
            newSessionUser.userKey = this.currentUser.userKey;
            newSessionUser.sessionId = this.settingsService.getSessionId();
            newSessionUser.setSectionValue(section, value);
            newSessionUser.actingAsModerator = this.currentUser.actingAsModerator;

            this.addUserToSession(newSessionUser);
        }
    }

    getSectionFromCoordinates(coordinateX: number, coordinateY: number) {
        if (coordinateX < XLIMIT && coordinateY < YLIMIT)
            return "manageability";
        else if (coordinateX < XLIMIT && coordinateY > YLIMIT)
            return "meaningfulness";
        else if (coordinateX > XLIMIT && coordinateY < YLIMIT)
            return "comprehensibility";
        else if (coordinateX > XLIMIT && coordinateY > YLIMIT)
            return "recovery";
        else
            return '';
    }

    getUserFromUserList(section: string, userList: SessionUser[], value: string): SessionUser {
        if (section == "manageability") {
            return userList.filter(x => x.handlingValue == value)[0];
        }
        else if (section == "comprehensibility") {
            return userList.filter(x => x.understandableValue == value)[0];
        }
        else if (section == "meaningfulness") {
            return userList.filter(x => x.meaningfulnessValue == value)[0];
        }
        else if (section == "recovery") {
            return userList.filter(x => x.recoverValue == value)[0];
        }
        else {
            return userList[-1];
        }
    }

    isModeratorConnectedAsUser() {
        return !this.settingsService.getIsModerator()
            && this.participants.filter(x => x.userKey == this.currentUser.userKey && x.isModeratorRecord == true).length > 0;
    }

    deleteDroppedFace(event) {
        var section = this.getSectionFromCoordinates(event.offsetX, event.offsetY);
        if (this.participants.find(x => x.detailId == event.target.id).userKey == this.currentUser.userKey) {
            var user = this.participants.find(x => x.detailId == event.target.id);

            // Moderator can only remove records from own session
            if (this.settingsService.getIsModerator() && user.userKey !== this.currentUser.userKey) return;

            // Former moderator for session is logged in as user and can only remove his/her own
            if (this.isModeratorConnectedAsUser() && !user.isModeratorRecord) return;
            
            if (user.isModeratorRecord && user.actingAsModerator) {
                this.currentUser.setSectionValue(section, "");
            }

            if (user.detailId == this.currentUser.detailId) {
                this.currentUser = user;
            }

            user.setSectionValue(section, "");

            if (user.handlingValue == ""
                && user.meaningfulnessValue == ""
                && user.understandableValue == ""
                && user.recoverValue == ""
                && user.detailId != this.currentUser.detailId)
                this.deleteUserFromDb(user);
            else
                this.postUserToDb(user);
        }
    }

    deleteFaceMobile(section: string, value: string) {
        var userList = this.participants.filter(x => x.userKey == this.currentUser.userKey).sort((a, b) => 0 - (a.detailId > b.detailId ? 1 : -1));
        var user = this.getUserFromUserList(section, userList, value);

        // Moderator can only remove records from own session
        if (this.settingsService.getIsModerator() && user.userKey !== this.currentUser.userKey) return;

        // Former moderator is logged in as user and can only remove his/her own
        if (this.isModeratorConnectedAsUser() && !user.isModeratorRecord) return;


        if (user.detailId == this.currentUser.detailId) {
            this.currentUser = user;
        }

        user.setSectionValue(section, "");

        if (user.handlingValue == ""
            && user.meaningfulnessValue == ""
            && user.understandableValue == ""
            && user.recoverValue == ""
            && user.detailId != this.currentUser.detailId) {
            this.deleteUserFromDb(user);
        }
        else {
            this.postUserToDb(user);
        }
    }

    countHandlingFaces(type: string) {
        return this.participants.filter(x => x.handlingValue !== null && x.handlingValue.trim() == type).length;
    }
    // -----------------------------------------------------------------------------------
    // Misc
    // -----------------------------------------------------------------------------------

    getIsSession() {
        return this.settingsService.getIsSession();
    }

    @HostListener("window:scroll", [])
    isScrollable() {
        if ((window.innerHeight + window.scrollY) < document.body.offsetHeight)
            return true;
        else
            return false;
    }

    getFaceUrl(id: string) {
        switch (id) {
            case "manageability": {
                if (this.faces.find(x => x.value == this.currentUser.handlingValue))
                    return "url(" + this.faces.find(x => x.value == this.currentUser.handlingValue).myImg + ")";
                return "";
            }
            case "comprehensibility": {
                if (this.faces.find(x => x.value == this.currentUser.understandableValue))
                    return "url(" + this.faces.find(x => x.value == this.currentUser.understandableValue).myImg + ")";
                return "";
            }
            case "meaningfulness": {
                if (this.faces.find(x => x.value == this.currentUser.meaningfulnessValue))
                    return "url(" + this.faces.find(x => x.value == this.currentUser.meaningfulnessValue).myImg + ")";
                return "";
            }
            case "recovery": {
                if (this.faces.find(x => x.value == this.currentUser.recoverValue))
                    return "url(" + this.faces.find(x => x.value == this.currentUser.recoverValue).myImg + ")";
                return "";
            }
            default: {
                return ''
            }
        }
    }

    getIsModerator() {
        return this.settingsService.getIsModerator();
    }

    getModeratorFacesMobile(value: string, section: string): number {
        switch (section) {
            case "manageability": {
                return this.participants.filter(x => x.handlingValue == value && x.userKey == this.currentUser.userKey).length
            }
            case "comprehensibility": {
                return this.participants.filter(x => x.understandableValue == value && x.userKey == this.currentUser.userKey).length
            }
            case "meaningfulness": {
                return this.participants.filter(x => x.meaningfulnessValue == value && x.userKey == this.currentUser.userKey).length
            }
            case "recovery": {
                return this.participants.filter(x => x.recoverValue == value && x.userKey == this.currentUser.userKey).length
            }
            default: {
                return -1
            }
        }
    }

    getLanguageItem(item: string) {
        if (this.languageItemList.length != 0)
            if (this.languageItemList.find(x => x.key == item))
                return this.languageItemList.find(x => x.key == item).textString;
        return "...";
    }

    openSnackBarInfo(message: string) {
        this.snackBar.open(message, 'close', {
            duration: 7000,
            panelClass: 'snackbar_info'
        });
    }

    openSnackBarErr(message: string) {
        SessionComponent.snackBarErrMsg = 'An unexpected error occurred. The error was: ' + message;
        let snRef = this.snackBar.open(message, 'Copy and close', {
            duration: 8000,
            panelClass: 'snackbar_error'
        });
        this.errCopy.snackBarCopy(snRef, document, SessionComponent.snackBarErrMsg);
    }

    isContinueDisabled() {
        return !(this.isMeetingKeyValid() && this.isModeratorKeyValid());
    }

    isModeratorKeyValid() {
        return this.moderatorKey.trim().length == 32 || this.moderatorKey.trim().length == 0;
    }

    isMeetingKeyValid() {
        return this.meetingKey.trim().length == 32;
    }

    isCreateDisabled() {
        return this.meetingName.trim().length < 2;
    }

    getNumberOfParticipants() {
        return this.participants.length;
    }

    getNumberOfLocalParticipants() {
        return this.participants.filter(x => x.userKey == this.currentUser.userKey).length;
    }

    getNumberOfRemoteParticipants() {
        return this.participants.filter(x => x.userKey != this.currentUser.userKey).length;
    }

    getLabelLength(label: string) {
        return label.length;
    }

    getFirstHalfOfLabel(label: string) {
        var temp = label.split(' ').slice(0, label.split(" ").length / 2).join(' ');
        return temp;
    }

    getRestOfLabel(label: string) {
        return label.split(' ').slice(label.split(" ").length / 2).join(' ');
    }

    getTitleText(section: string): string {
        switch (section) {
            case "manageability":
                return this.getLanguageItem("manageability") +
                    "\n\n" + this.getLanguageItem("manageabilityrow1") +
                    "\n" + this.getLanguageItem("manageabilityrow2") +
                    "\n" + this.getLanguageItem("manageabilityrow3") +
                    "\n" + this.getLanguageItem("manageabilityrow4");
            case "meaningfulness":
                return this.getLanguageItem("meaningfulness") +
                    "\n\n" + this.getLanguageItem("meaningfulnessrow1") +
                    "\n" + this.getLanguageItem("meaningfulnessrow2") +
                    "\n" + this.getLanguageItem("meaningfulnessrow3") +
                    "\n" + this.getLanguageItem("meaningfulnessrow4");
            case "comprehensibility":
                return this.getLanguageItem("comprehensibility") +
                    "\n\n" + this.getLanguageItem("comprehensibilityrow1") +
                    "\n" + this.getLanguageItem("comprehensibilityrow2") +
                    "\n" + this.getLanguageItem("comprehensibilityrow3") +
                    "\n" + this.getLanguageItem("comprehensibilityrow4");
            case "recovery":
                return this.getLanguageItem("recovery") +
                    "\n\n" + this.getLanguageItem("recoveryrow1") +
                    "\n" + this.getLanguageItem("recoveryrow2") +
                    "\n" + this.getLanguageItem("recoveryrow3") +
                    "\n" + this.getLanguageItem("recoveryrow4");
            default:
                return ''
        }
    }

    getTitle() {
        return this.circleTitle;
    }

    setTitle(event) {
        var section = this.getSectionFromCoordinates(event.offsetX, event.offsetY);
        this.circleTitle = this.getTitleText(section);
    }

    clearTitle() {
        this.circleTitle = "";
    }

    setXY() {
        var startX = 427;
        var startY = 487;
        var leftX = startX - 55;
        var downY = startY - 45;
        var rightX = startX + 55;
        var upY = startY + 45;
        var iteration = 0;
        this.participants.forEach(x => {
            if (this.iterationList.includes(iteration)) {
                downY -= 70;
                upY += 70;
                if (iteration < 27) {
                    leftX = startX - 55;
                    rightX = startX + 55;
                }
                else {
                    leftX = startX - 85;
                    rightX = startX + 85;
                }
            }
            if (iteration == 28) {
                leftX = startX - 85;
                rightX = startX + 85;
                downY = startY - 75;
                upY = startY + 75;
            }
            x.handlingX = leftX;
            x.handlingY = downY;
            x.understandableX = rightX;
            x.understandableY = downY;
            x.meaningfulnessX = leftX;
            x.meaningfulnessY = upY;
            x.recoverX = rightX;
            x.recoverY = upY;
            leftX -= 70;
            rightX += 70;
            iteration += 1;

        });
    }

    getDragFaceBorder() {
        if (this.settingsService.getIsModerator())
            return "#FFEE00";
        else
            return "#00FF33";
    }

    getFaceColor(userKey: string, face: string, isModeratorRecord: boolean): { fill: string; stroke: string; 'stroke-width': string; } {
        switch (face) {
            case "excellent":
                if (this.isModeratorConnectedAsUser() && userKey == this.currentUser.userKey && !isModeratorRecord)
                    return { 'fill': '#151843', 'stroke': '#fff', 'stroke-width': '2px' }
                else if (this.settingsService.getIsModerator() && userKey == this.currentUser.userKey)
                    return { 'fill': '#151843', 'stroke': '#FFEE00', 'stroke-width': '4px' }
                else if (userKey == this.currentUser.userKey)
                    return { 'fill': '#151843', 'stroke': '#00FF33', 'stroke-width': '4px' }
                else
                    return { 'fill': '#151843', 'stroke': '#fff', 'stroke-width': '2px' }
            case "good":
                if (this.isModeratorConnectedAsUser() && userKey == this.currentUser.userKey && !isModeratorRecord)
                    return { 'fill': '#9cb2a2', 'stroke': '#fff', 'stroke-width': '2px' }
                else if (this.settingsService.getIsModerator() && userKey == this.currentUser.userKey)
                    return { 'fill': '#9cb2a2', 'stroke': '#FFEE00', 'stroke-width': '4px' }
                else if (userKey == this.currentUser.userKey)
                    return { 'fill': '#9cb2a2', 'stroke': '#00FF33', 'stroke-width': '4px' }
                else
                    return { 'fill': '#9cb2a2', 'stroke': '#fff', 'stroke-width': '2px' }
            case "insufficient":
                if (this.isModeratorConnectedAsUser() && userKey == this.currentUser.userKey && !isModeratorRecord)
                    return { 'fill': '#dbbf8d', 'stroke': '#fff', 'stroke-width': '2px' }
                else if (this.settingsService.getIsModerator() && userKey == this.currentUser.userKey)
                    return { 'fill': '#dbbf8d', 'stroke': '#FFEE00', 'stroke-width': '4px' }
                else if (userKey == this.currentUser.userKey)
                    return { 'fill': '#dbbf8d', 'stroke': '#00FF33', 'stroke-width': '4px' }
                else
                    return { 'fill': '#dbbf8d', 'stroke': '#fff', 'stroke-width': '2px' }
            case "poor":
                if (this.isModeratorConnectedAsUser() && userKey == this.currentUser.userKey && !isModeratorRecord)
                    return { 'fill': '#de0621', 'stroke': '#fff', 'stroke-width': '2px' }
                else if (this.settingsService.getIsModerator() && userKey == this.currentUser.userKey)
                    return { 'fill': '#de0621', 'stroke': '#FFEE00', 'stroke-width': '4px' }
                else if (userKey == this.currentUser.userKey)
                    return { 'fill': '#de0621', 'stroke': '#00FF33', 'stroke-width': '4px' }
                else
                    return { 'fill': '#de0621', 'stroke': '#fff', 'stroke-width': '2px' }
            default:
                return { 'fill': '', 'stroke': '', 'stroke-width': '' }
        }
    }
}