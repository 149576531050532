import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { protectedResources } from '../auth-config';

@Injectable( {
    providedIn: 'root'
} )
export class APIService {
    constructor( private http: HttpClient) { }

    httpOptions() { 
        return {
            headers: new HttpHeaders( {
                'Content-Type': 'application/json; charset=UTF-8'
                }
            )};
    }

    getApiBaseUrl() {
        let url = window.location.host.split( ':' )[0];
        return url === 'localhost'
            ? protectedResources.wbtApiLocal.endpoint 
            : url === 'wbd.scania.com'
                ? protectedResources.wbtApiProd.endpoint 
                : protectedResources.wbtApiDevTest.endpoint 
    }
    
    getAPI( endpoint: string ): Observable<any> {
        var url = `${this.getApiBaseUrl()}/${endpoint}`;
        return this.http.get( url, this.httpOptions(), );
    }

    postAPI( endpoint: string, payload: any ): Observable<any> {
        var url = `${this.getApiBaseUrl()}/${endpoint}`;
        return this.http.post( url, JSON.stringify( payload ), this.httpOptions() );
    }

    deleteByEnpointApi( endpoint: string ) {
        var url = `${this.getApiBaseUrl()}/${endpoint}`;
        return this.http.delete( url , this.httpOptions() );
    }
}